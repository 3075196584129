import LadiesCategory from './LadiesCategory.vue'
import LadiesCategoryCreate from './Create.vue'

export default [
    {
        path: '/ladies_category',
        name: 'LadiesCategory',
        component: LadiesCategory
    },
    {
        path: '/ladies_category/create',
        name: 'LadiesCategoryCreate',
        component: LadiesCategoryCreate
    },
    {
        path: '/ladies_category/edit/:id',
        name: 'LadiesCategoryEdit',
        component: LadiesCategoryCreate
    },
]
